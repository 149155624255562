import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';
import { LoginService } from '../service/LoginService';
import md5 from 'md5/md5.js';

export const LoginRouting = () => {

  const toast = useRef(null);
  const [Login, setLogin] = useState(null);
  const [Senha, setSenha] = useState(null);
  const [Progress, setProgress] = useState(false);

  const gerarNonce = () => {
    return (Math.random(new Date().getUTCMilliseconds())).toString().replace('.', '');
  };
  const gerarMD5 = (Usuario, Senha, Nonce) => {
    return md5(Usuario + Nonce + Senha)
  };

  const validarCampos = () => {
    if (Login === "" || Login === null) {
      toast.current.show({ severity: 'warn', summary: 'Atenção', detail: "Digite seu login de usuário.", life: 5000 });
      return document.getElementById("login").focus();
    }
    if (Senha === "" || Senha === null) {
      toast.current.show({ severity: 'warn', summary: 'Atenção', detail: "Digite sua senha.", life: 5000 });
      return document.getElementById("senha").focus();
    }
    return true;
  }

  const logar = () => {
    if (validarCampos()) {
      setProgress(true);
      const _loginService = new LoginService();
      const _cnonce = gerarNonce();
      const _loginDTO = {
        name: Login.toLowerCase(),
        cnonce: _cnonce,
        md5: gerarMD5(Login.toLowerCase(), Senha.toLowerCase(), _cnonce)
      }
      localStorage.setItem("token", _loginDTO.md5);
      _loginService.autenticar(_loginDTO)
        .then((resp) => {

          if (resp.error)
            return erroLogin(resp.error);

          if (resp.data)
            return sucessoLogin(resp.data);

          erroLogin("Tente novamente ou clique em 'Lembrar senha'.");

        })
    }
  }

  const sucessoLogin = (data) => {
    sessionStorage.setItem('auth', JSON.stringify(data));
    setTimeout(() => {
      window.location.reload();
    }, 500);
    setProgress(false);
  }

  const erroLogin = (error) => {
    setProgress(false);
    toast.current.show({ severity: 'error', summary: 'Acesso negado!', detail: error, life: 5000 });
  }

  const senhaOnEnter = (e) => {
    if (e.keyCode === 13)
      logar();
  }


  const header = <div style={{ textAlign: "center", fontSize: "22px", fontWeight: "500" }}>
    <img src={`assets/logo-web-viewer.png`} style={{ height: '40px' }} />
  </div>;

  const footer = <div style={{ textAlign: "center" }}>
    <div style={{ fontSize: "12px", color: "#6e84a3" }}>
      {sessionStorage.getItem('versao')}
    </div>
  </div>;

  const progress = Progress ? <ProgressSpinner style={{ width: '20px', height: '20px', top: "8px" }} strokeWidth="5" animationDuration=".5s" /> : null;

  return (

    <div className="p-d-flex p-jc-center">

      <Toast ref={toast} />

      <Card footer={footer} className="p-md-3 " style={{ padding: "20px", paddingBottom: "0", marginTop: "10vw" }}>

        <div style={{ textAlign: 'center', marginBottom: '10px' }}>
          <img src={`assets/logo-web-viewer.png`} height='50' />
        </div>

        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="login">Login</label>
            <span className="p-input-icon-left">
              <i className="pi pi-user" />
              <InputText
                id="login"
                value={Login}
                onChange={(e) =>
                  setLogin(e.target.value)}
                placeholder="Login de usuário"
                autoFocus
              />
            </span>
          </div>
          <div className="p-field">
            <label htmlFor="senha">Senha</label>
            <Password
              id="senha"
              toggleMask
              feedback={false}
              value={Senha}
              onChange={(e) => setSenha(e.target.value)}
              onKeyDown={(e) => senhaOnEnter(e)} placeholder="Senha"
            />
          </div>
          <Button
            label="Entrar"
            onClick={logar}
          />
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "20px 100%", padding: "7px" }}>
          <div>{progress}</div>
          <Button label="Lembrar senha" className="p-button-link" style={{ textAlign: "right" }} />
        </div>

      </Card>

    </div>

  );

}
