import axios from 'axios';

export class ServiceApi {

  static _getUrl() {
    return axios.get('../config.json')
      .then(res => {
        const servico = res.data.Servico;
        sessionStorage.setItem('config', JSON.stringify(res.data));
        return servico;
      })
      .then(url => {
        if (url[url.length - 1] === "/")
          return url;
        return url + "/";
      });
  }

  static post(address, body) {
    return this._getUrl()
      .then(root => axios.post(`${root}${address}`, body))
      .then(
        resp => {
          return { data: resp.data };
        },
        error => {
          let errorObject = Object.assign({}, error).response;
          if (errorObject)
            return { error: errorObject.data.Message };
          return { error: 'Erro interno do servidor.' };
        }
      );
  }

}
