import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import useDebouncedEffect from 'use-debounced-effect';
import { addLocale } from 'primereact/api';

addLocale('pt', {
  firstDayOfWeek: 1,
  dayNames: ["domingo", "segunda", "terça", "quarta", "quinta", "sexta", "sábado"],
  dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
  dayNamesMin: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
  monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
  monthNamesShort: ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"],
  today: 'Hoje',
  clear: 'Limpar'
});

function TableSearchFilter(props) {

  const {
    values,
    onValueChange,
    onSort
  } = props;

  const [date1] = useState(new Date);
  const [date2] = useState(new Date);
  const [patientName, setPatientName] = useState(null);
  const [patientId, setPatientId] = useState(null);
  const [accessionNumber, setAccessionNumber] = useState(null);
  const [modalities, setModalities] = useState(null);
  const [studyDescription, setStudyDescription] = useState(null);
  const [objFilt, setObjFilt] = useState(null);

  useEffect(() => {

    onKeyDown();

    const getMemoFilter = JSON.parse(sessionStorage.getItem('memoFilter'));

    if (getMemoFilter === null)
      return setMemoFilter();

    if (values.studyDateFrom === null || values.studyDateTo === null) {
      values.studyDateFrom = getMemoFilter.studyDateFrom ? moment(getMemoFilter.studyDateFrom).toDate() : new Date();
      values.studyDateTo = getMemoFilter.studyDateTo ? moment(getMemoFilter.studyDateTo).toDate() : new Date();
    }

    const arrFilterValues = ['AccessionNumber', 'PatientID', 'PatientName', 'StudyDescription', 'modalities'];

    arrFilterValues.forEach(input => {
      if (values[input] === "" || values[input] === null)
        values[input] = input === 'PatientName' || input === 'StudyDescription' ? `*${getMemoFilter[input]}*` : getMemoFilter[input];
    });

  }, [values]);

  const onKeyDown = () => {

    document.getElementById('dataInicial').addEventListener('keydown', e => {
      if (e.key === "Enter")
        onSelectDate1({ value: formatDateFilter(e.target.value) })
    });

    document.getElementById('dataFinal').addEventListener('keydown', e => {
      if (e.key === "Enter")
        onSelectDate2({ value: formatDateFilter(e.target.value) })
    });

    const inputs = ['filter-PatientName', 'filter-PatientID', 'filter-AccessionNumber', 'filter-StudyDescription'];
    inputs.map(input => {
      document.getElementById(input).addEventListener('keydown', e => {
        if (e.key === "Enter")
          document.getElementById('btnBuscar').click();
      });
    })

  }

  const formatDateFilter = (date) => {
    date = date.split('/');
    return moment(`${date[1]}/${date[0]}/${date[2]}`).toDate();
  }

  const setMemoFilter = (key, value) => {
    try {
      values[key] = value;
      sessionStorage.setItem('memoFilter', JSON.stringify(values));
    } catch (error) { }
  }

  useDebouncedEffect(() => {

    if (patientName !== null)
      valueChangeFilt('PatientName', patientName);

    if (patientId !== null)
      valueChangeFilt('PatientID', patientId);

    if (accessionNumber !== null)
      valueChangeFilt('AccessionNumber', accessionNumber);

    if (modalities !== null)
      valueChangeFilt('modalities', modalities);

    if (studyDescription !== null)
      valueChangeFilt('StudyDescription', studyDescription);

  }, 200, [patientName, accessionNumber, patientId, modalities, studyDescription]);

  const valueChangeFilt = (nameFilt, stateFilt) => {
    if (stateFilt !== values[nameFilt]) {
      setMemoFilter(nameFilt, stateFilt);
      setObjFilt({ NameFilt: nameFilt, StateFilt: stateFilt });
    }
  }

  const onSelectDate1 = (e) => {
    setDatesChange({
      selDate1: e.value,
      selDate2: values['studyDateTo'] === null ? date2 : values['studyDateTo']
    });
  }

  const onSelectDate2 = (e) => {
    setDatesChange({
      selDate1: values['studyDateFrom'] === null ? date1 : values['studyDateFrom'],
      selDate2: e.value
    });
  }

  const setDatesChange = (obj) => {
    //onValueChange('studyDateFrom', obj.selDate1);
    //onValueChange('studyDateTo', obj.selDate2);
    const _patitntName = patientName ? patientName : values['PatientName'].replaceAll('*', '');
    setPatientName(_patitntName ? _patitntName : "");
    setMemoFilterDate(obj);
  }

  const buscar = () => {
    if (objFilt === null) {
      const _objFilt = {
        NameFilt: "PatientName",
        StateFilt: ""
      }
      return onValueChange(_objFilt.NameFilt, `*${_objFilt.StateFilt}*`);
    }
    onValueChange(objFilt.NameFilt, objFilt.NameFilt === 'PatientName' || objFilt.NameFilt === 'StudyDescription' ? `*${objFilt.StateFilt}*` : objFilt.StateFilt);
  }

  const setMemoFilterDate = (obj) => {
    let objDate = values;
    objDate.studyDateFrom = obj.selDate1;
    objDate.studyDateTo = obj.selDate2;
    sessionStorage.setItem('memoFilter', JSON.stringify(objDate));
  }

  const openCalendar = (comp) => {
    const el = document.getElementById(comp).getElementsByTagName('input')[0];
    el.focus();
    el.select();
  }

  const monthNavigatorTemplate = (e) => {
    return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{ lineHeight: 1 }} />;
  }

  const yearNavigatorTemplate = (e) => {
    return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} className="p-ml-2" style={{ lineHeight: 1 }} />;
  }

  return (
    <div className="p-formgroup-inline">
      <div className="p-field" style={{ marginBottom: '0', width: '30vh' }}>
        <label htmlFor="filter-PatientName" style={{ padding: '7px' }}>Nome do Paciente</label>
        <InputText
          id="filter-PatientName"
          type="text"
          value={patientName === null ? values.PatientName.replaceAll('*', '') : patientName}
          onChange={e => setPatientName(e.target.value)}
        />
      </div>

      <div className="p-field" style={{ marginBottom: '0' }}>
        <label htmlFor="filter-PatientID" style={{ padding: '7px' }}>Prontuário</label><br></br>
        <InputText
          id="filter-PatientID"
          type="text"
          value={patientId === null ? values.PatientID : patientId}
          onChange={e => setPatientId(e.target.value)}
          style={{ width: '130px' }}
        />
      </div>

      <div className="p-field" style={{ marginBottom: '0' }}>
        <label htmlFor="filter-AccessionNumber" style={{ padding: '7px' }}>AcNumber</label><br></br>
        <InputText
          id="filter-AccessionNumber"
          type="text"
          value={accessionNumber === null ? values.AccessionNumber : accessionNumber}
          onChange={e => setAccessionNumber(e.target.value)}
          style={{ width: '130px' }}
        />
      </div>

      <div className="p-field" style={{ marginBottom: '0' }}>
        <label htmlFor="dataInicial" style={{ padding: '7px' }}>Data Inicial</label><br></br>
        <div className="p-inputgroup">
          <Calendar
            id="dataInicial"
            locale={'pt'}
            dateFormat="dd/mm/yy"
            mask={'99/99/9999'}
            value={values['studyDateFrom'] === null ? date1 : values['studyDateFrom']}
            onSelect={e => onSelectDate1(e)}
            style={{ width: '110px' }}
            monthNavigator yearNavigator yearRange={`2000:${moment().format('YYYY')}`}
            monthNavigatorTemplate={monthNavigatorTemplate}
            yearNavigatorTemplate={yearNavigatorTemplate}
            showButtonBar
          />
          <Button icon="pi pi-calendar" onClick={() => openCalendar('dataInicial')} />
        </div>
      </div>

      <div className="p-field" style={{ marginBottom: '0' }}>
        <label htmlFor="dataFinal" style={{ padding: '7px' }}>Data Final</label><br></br>
        <div className="p-inputgroup">
          <Calendar
            id="dataFinal"
            locale={'pt'}
            dateFormat="dd/mm/yy"
            mask={'99/99/9999'}
            value={values['studyDateTo'] === null ? date2 : values['studyDateTo']}
            onSelect={e => onSelectDate2(e)}
            style={{ width: '110px' }}
            monthNavigator yearNavigator yearRange={`2000:${moment().format('YYYY')}`}
            monthNavigatorTemplate={monthNavigatorTemplate}
            yearNavigatorTemplate={yearNavigatorTemplate}
            showButtonBar
          />
          <Button icon="pi pi-calendar" onClick={() => openCalendar('dataFinal')} />
        </div>
      </div>

      <div className="p-field" style={{ marginBottom: '0' }}>
        <label htmlFor="filter-modalities" style={{ padding: '7px' }}>Modalidade</label><br></br>

        <Dropdown value={modalities === null ? values.modalities : modalities}
          options={[
            { name: 'Todas', value: '' },
            { name: 'CR', value: 'CR' },
            { name: 'CT', value: 'CT' },
            { name: 'DX', value: 'DX' },
            { name: 'MR', value: 'MR' },
            { name: 'OT', value: 'OT' },
            { name: 'PR', value: 'PR' },
            { name: 'RX', value: 'RX' },
            { name: 'SC', value: 'SC' },
            { name: 'SR', value: 'SR' },
            { name: 'US', value: 'US' },
            { name: 'XA', value: 'XA' }
          ]}
          onChange={e => setModalities(e.target.value)}
          optionLabel="name"
          placeholder="Selecionar"
          scrollHeight="500px"
        />

      </div>

      <div className="p-field" style={{ marginBottom: '0', width: '30vh' }}>
        <label htmlFor="filter-StudyDescription" style={{ padding: '7px' }}>Descrição</label>
        <InputText
          id="filter-StudyDescription"
          type="text"
          value={studyDescription === null ? values.StudyDescription.replaceAll('*', '') : studyDescription}
          onChange={e => setStudyDescription(e.target.value)}
        />
      </div>

      <div className='p-field' style={{ marginBottom: '0' }}>
        <label style={{ padding: '7px', visibility: 'hidden' }}>Buscar</label>
        <div>
          <Button id="btnBuscar" label='Buscar' icon='pi pi-search' onClick={() => buscar()} />
        </div>
      </div>

    </div>

  );

}

TableSearchFilter.propTypes = {
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      displayText: PropTypes.string.isRequired,
      fieldName: PropTypes.string.isRequired,
      inputType: PropTypes.oneOf(['text', 'date-range']).isRequired,
      size: PropTypes.number.isRequired,
    })
  ).isRequired,
  values: PropTypes.object.isRequired,
  onSort: PropTypes.func.isRequired,
  sortFieldName: PropTypes.string,
  sortDirection: PropTypes.oneOf([null, 'asc', 'desc']),
};

TableSearchFilter.defaultProps = {};

export { TableSearchFilter };
export default TableSearchFilter;
