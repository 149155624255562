import { ServiceApi } from "./Api";

export class HistoricoService {

  acoesHistorico = {
    visualizar: 43,
    baixar: 44
  }

  postAdicionarHistorico(operacao, studyUid) {
    try {
      const getAuth = JSON.parse(sessionStorage.getItem("auth"));
      const usuarioId = getAuth.Id;
      return ServiceApi.post(`Api/Pedido/PostAdicionarHistoricoWebViewer/?operacao=${operacao}&usuarioId=${usuarioId}&studyUid=${studyUid}`);
    } catch (error) {
      console.log(error);
    }
  }

}
