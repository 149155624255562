import moment from 'moment';

/** Versão */
sessionStorage.setItem('versao', `© ${moment().format('YYYY')} Tzion WebViewer 2022.001.01.07`);

const auth = JSON.parse(sessionStorage.getItem('auth'));

export function isAuth() {

  if (window.location.href.indexOf("viewer/") !== -1)
    return true;

  try {
    if (auth)
      return auth.Id
        && auth.Cnonce
        && auth.Login
        && auth.Md5
        && auth.Token;
  } catch (error) {
    console.error(error);
  }

  return false;

}
