import './StudyList.styl';
import '../../design/styles/dataTable.css';
import React, { useEffect, useReducer, useState } from 'react';
import classNames from 'classnames';
import TableSearchFilter from './TableSearchFilter.js';
import PropTypes from 'prop-types';
import { StudyListLoadingText } from './StudyListLoadingText.js';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import 'primereact/resources/themes/bootstrap4-dark-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import axios from 'axios';
import moment from 'cypress/node_modules/moment';

/**
 *
 *
 * @param {*} props
 * @returns
 */
function StudyList(props) {
  const {
    isLoading,
    hasError,
    studies,
    sort,
    onSort: handleSort,
    filterValues,
    onFilterChange: handleFilterChange,
    onSelectItem: handleSelectItem,
    studyListDateFilterNumDays,
    displaySize,
    config
  } = props;

  sessionStorage.setItem('memoStudies', JSON.stringify(studies));

  const { t, ready: translationsAreReady } = useTranslation('StudyList');
  let objFields = { PatientName: false, PatientID: false, AccessionNumber: false, StudyDate: false, modalities: false, StudyDescription: false };
  const [sortDirection, setSortDirection] = useState('asc');
  const [iconSortButtonHeader, setIconSortButtonHeader] = useState(objFields);
  const [dialogPrintLaudo, setDialogPrintLaudo] = useState(false);
  const [dialogMax, setDialogMax] = useState(false);
  const [dialogHeader, setDialogHeader] = useState("");
  const [progressBar, setProgressBar] = useState(false);

  const ordenar = (fieldname) => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    handleSort(fieldname, sortDirection);
  }

  const setIconOrder = (field) => {
    objFields[field] = true;
    setIconSortButtonHeader(objFields);
  }

  const buttonHeader = (label, field) => {
    return <Button
      label={label}
      icon={iconSortButtonHeader[field] ? `pi pi-${sortDirection === 'asc' ? 'sort-amount-down-alt' : 'sort-amount-down'}` : ''}
      iconPos='right'
      className="p-button-text"
      onClick={() => { setIconOrder(field); ordenar(field) }}
      style={{ textAlign: 'left', width: '100%' }}
    />
  }

  const adicionarHistorico = (studyInstanceUID) => {
    try {
      const auth = JSON.parse(sessionStorage.getItem('auth'));
      const usuarioId = auth.Id;
      const operacao = 46;
      axios.post(`${config.Servico}/Api/Pedido/PostAdicionarHistoricoWebViewer?operacao=${operacao}&usuarioId=${usuarioId}&studyUid=${studyInstanceUID}`)
        .catch(error => { console.log(error); })
    } catch (error) {
      console.error(error);
    }
  }

  const imprimirLaudo = (value) => {
    setProgressBar(true);
    setDialogMax(false);
    setDialogHeader(`${value.PatientName} - ${value.AccessionNumber}`);
    setDialogPrintLaudo(true);
    setTimeout(() => {
      const caminho = `${config.Servico}/api/Pedido/GetResultadoPdfPorStudyUid?studyUid=${value.StudyInstanceUID}`;
      try {
        document.getElementsByTagName('embed')[0].remove();
      } catch (error) { }
      try {
        const embed = document.createElement('embed');
        embed.src = caminho;
        embed.width = '100%';
        embed.height = '100%';
        document.getElementById(`printLaudo`).append(embed);
        setDialogContentSize();
        adicionarHistorico(value.StudyInstanceUID);
      } catch (error) {
        console.error(error);
      }
      setProgressBar(false);
    }, 500);
  }

  const handleOnSelectionChange = (e) => {

    localStorage.removeItem('studyAct');

    if (e.originalEvent.target.className === 'p-button-icon p-c pi pi-print')
      return imprimirLaudo(e.value);

    localStorage.setItem('studyAct', JSON.stringify({
      StudyInstanceUID: e.value.StudyInstanceUID,
      PatientID: e.value.PatientID,
      StudyDate: e.value.StudyDate
    }));

    handleSelectItem(e.value.StudyInstanceUID);

  }

  const setDialogContentSize = () => {
    try {
      setTimeout(() => {
        const pDialogContent = document.getElementsByClassName('p-dialog-content')[0];
        const embed = document.getElementsByTagName('embed')[0];
        pDialogContent.style.padding = '0';
        pDialogContent.style.overflow = 'hidden';
        embed.style.height = `${pDialogContent.clientHeight}px`;
      }, 200);
    } catch (ex) { }
  }

  return translationsAreReady ? (
    <div>
      {!isLoading &&
        <div>

          <Dialog
            id="dialogPrintLaudo"
            onMaximize={e => {
              setDialogMax(e.maximized);
              setDialogContentSize();
            }}
            header={`Visualizar/Imprimir Laudo - ${dialogHeader}`}
            maximizable
            maximized={dialogMax}
            modal visible={dialogPrintLaudo}
            style={{ width: '80vw', height: '80vh' }}
            contentStyle={{ background: '#ddd', marging: '0', padding: '0', fontSize: '20px' }}
            onHide={() => setDialogPrintLaudo(false)}>
            <div id='printLaudo'>
              <ProgressBar mode='indeterminate' style={{ borderRadius: '0', height: '5px', display: progressBar ? '' : 'none' }} />
            </div>
          </Dialog>

          <div className="card">
            <DataTable
              header={<TableSearchFilter
                values={filterValues}
                onSort={handleSort}
                onValueChange={handleFilterChange}
                sortFieldName={sort.fieldName}
                sortDirection={sort.direction}
                studyListDateFilterNumDays={studyListDateFilterNumDays}
              />}
              value={studies}
              selectionMode="single"
              className="p-datatable-responsive-demo"
              paginator
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} registros" rows={10} rowsPerPageOptions={[10, 20, 50]}
              autoLayout
              resizableColumns
              onSelectionChange={e => handleOnSelectionChange(e)}
              footer={<div>{sessionStorage.getItem('versao')}</div>}
            >
              <Column field="PatientName" header={buttonHeader('Paciente', 'PatientName')} />
              <Column field="PatientID" header={buttonHeader('Prontuário', 'PatientID')} />
              <Column field="AccessionNumber" header={buttonHeader('AcNumber', 'AccessionNumber')} style={{ width: '150px' }} />
              <Column field="StudyDate" header={buttonHeader('Data Estudo', 'StudyDate')} body={(e) => moment(e.StudyDate).format("DD/MM/YYYY")} style={{ width: '170px' }} />
              <Column field="modalities" header={buttonHeader('Modalidades', 'modalities')} style={{ width: '150px' }} />
              <Column field="StudyDescription" header={buttonHeader('Descrição', 'StudyDescription')} />
              <Column field="Laudo" header={<Button label='Laudo' className='p-button-text' style={{ display: config.ReportCenter ? '' : 'none', cursor: 'default' }} />} />
              {/* <Column field="StudyInstanceUID" header="Instance UID" sortable /> */}
            </DataTable>
          </div>
        </div>

      }

    </div>
  ) : null;
}

StudyList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  studies: PropTypes.array.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  // ~~ SORT
  sort: PropTypes.shape({
    fieldName: PropTypes.string,
    direction: PropTypes.oneOf(['desc', 'asc', null]),
  }).isRequired,
  onSort: PropTypes.func.isRequired,
  // ~~ FILTERS
  filterValues: PropTypes.shape({
    PatientName: PropTypes.string.isRequired,
    PatientID: PropTypes.string.isRequired,
    AccessionNumber: PropTypes.string.isRequired,
    StudyDate: PropTypes.string.isRequired,
    modalities: PropTypes.string.isRequired,
    StudyDescription: PropTypes.string.isRequired,
    patientNameOrId: PropTypes.string.isRequired,
    accessionOrModalityOrDescription: PropTypes.string.isRequired,
    allFields: PropTypes.string.isRequired,
    studyDateTo: PropTypes.any,
    studyDateFrom: PropTypes.any,
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  studyListDateFilterNumDays: PropTypes.number,
  displaySize: PropTypes.string,
};

StudyList.defaultProps = {};

export { StudyList };
