import React, { Component } from 'react';
import { LoginRouting } from './login/LoginRouting';

class Login extends Component {

  render() {
    return (<LoginRouting />);
  }

}

export default Login;
